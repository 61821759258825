import React, { useState, useEffect } from "react";
import { useAppContext } from '../../useAppState'
import { gql } from "@apollo/client";
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Link } from "react-router-dom";
import { Modal, Button, Alert } from "react-bootstrap";
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';
import MoonLoader from "react-spinners/MoonLoader";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import moment from "moment";



const ME_STUDENT = gql`
  query MeStudent{
    meStudent{
        id
        firstName
        lastName
        profile
        email
        affiliation
        title
        manager
        province
        city
        department
        branch
        proprietaire 
        territoire 
        dp 
        proprietaireAyantPrestige 
        cabinetOuAgence 
        focus1_2_virage 
        dcda 
        nivAutoriteAuto
        nivAutoriteHabitation
        webzone
        coursesTypeAccess
        specificAccessStartDate
        specificAccessEndDate
        cohortesJson
        cohortes{
            cohorteId
            trainerRelatedId
            filesUploaded
            startDate
            endDate
            continuousModeCourses
            documents{
                title
                fileName
                fullUrl
            }
            links
            blockedModules
        }
        lastTimeLogged
        hasAdminAccount
    }
  }
`;

const ADD_OR_UPDATE_IP_USED = gql`
  mutation AddOrUpdateIpUsed($id: ID!, $ipUsed: String!) {
    addOrUpdateIpUsed(id: $id, ipUsed: $ipUsed)
  }
`;


const InfoPageCenteredModal = (props) => {

    const { state } = useAppContext();
    const { t } = useTranslation();

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='text-center'>
                <Modal.Title id="contained-modal-title-vcenter" >
                    {t('dashboardContainerFirstVisit')}
                    {/* {t('dashboardContainerFirstVisit', { lng: 'fr' })}
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    {t('dashboardContainerFirstVisit', { lng: 'en' })} */}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='text-center' style={{ fontSize: 18 }}>
                    {t('dashboardContainerMessageFirstVisit')}
                    {/* {t('dashboardContainerMessageFirstVisit', { lng: 'fr' })}
                    <hr />
                    {t('dashboardContainerMessageFirstVisit', { lng: 'en' })} */}
                </p>
                <div className={'btn-info-cnt'}>
                    <Link to={'/info'}>
                        <Button
                            className='mt-3 btn-info'
                            variant={"dark"}
                            onClick={props.onHide} >
                            {t('dashboardContainerVisitInfoPage')}
                            {/* {t('dashboardContainerVisitInfoPage', { lng: 'fr' })}
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            {t('dashboardContainerVisitInfoPage', { lng: 'en' })} */}
                        </Button>
                    </Link>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} variant={"dark"}>{t('close')}</Button>
            </Modal.Footer>
        </Modal>
    );
};



const DashboardContainer = (props) => {

    const { t } = useTranslation();
    const { state, actions } = useAppContext();
    const infoPageVisited = Cookies.get('infoPageVisited');

    const [navElements, setNavElements] = useState({});
    const [burgerClass, setBurgerClass] = useState('hamburger hamburger--collapse');
    const [mobileMenuClass, setMobileMenuClass] = useState('menu-mobile');
    const [currentTime] = useState(new Date());
    const [greetingMsg, setGreetingMsg] = useState('Bonjour');
    const [searchTerm, setSearchTerm] = useState('');
    const [searchPlaceholder, setSearchPlaceholder] = useState(t('menu-search-placeholder'));
    const [infoPageModalShow, setInfoPageModalShow] = useState(infoPageVisited ? false : true);




    const { loading, error } = useQuery(ME_STUDENT,
        {
            errorPolicy: 'all',
            onCompleted(res) {
                console.log(res)
                if (res.meStudent) {
                    let infoStudent = { ...res.meStudent };
                    console.log(infoStudent);

                    // SET LANGUAGE to FR if user is from Quebec and no language is set in cookie
                    if (!Cookies.get('lang') && (/^qc$/i.test(infoStudent.province) || infoStudent.province === 'QC - Quebec')) {
                        actions.changeLang('fr');
                    }

                    infoStudent.cohortesJson = infoStudent.cohortesJson ? JSON.parse(infoStudent.cohortesJson) : [];

                    if (infoStudent.cohortes && infoStudent.cohortes.length > 0) {
                        infoStudent.cohortes = infoStudent.cohortes.map(cohorte => {
                            cohorte.blockedModules = cohorte.blockedModules ? JSON.parse(cohorte.blockedModules) : {};
                            return cohorte;
                        });
                    }

                    if (infoStudent.email.indexOf('@intact') !== -1 || infoStudent.email.indexOf('@belair') !== -1) {
                        infoStudent.isInternal = true;
                        if (moment(infoStudent.lastTimeLogged).isBefore(new Date(), 'day')) {
                            Cookies.set('directLinkPath', window.location.href.replace(window.location.origin, ''), { expires: 1 });
                            window.location.href = process.env.REACT_APP_ENV === 'production' ? 'https://icite.intactfc.com/intact/login' : 'https://icite.intactfc.com/dev/intact/login';
                        }
                    } else {
                        infoStudent.isInternal = false;
                    }

                    actions.setUserInfos(infoStudent);
                    actions.setMeStudentLoginError(null);

                    let earlierDebutDate = infoStudent.specificAccessStartDate ? moment(infoStudent.specificAccessStartDate).format('YYYY-MM-DD') : null;
                    let lastEndDate = infoStudent.specificAccessEndDate ? moment(infoStudent.specificAccessEndDate).format('YYYY-MM-DD') : null;

                    const startAccessError = (t('dashboardContainerStartAccessError')) + earlierDebutDate;
                    const endAccessError = (t('dashboardContainerEndAccessError')) + (lastEndDate == null ? '' : moment(lastEndDate).format('YYYY-MM-DD'));
                    let today = new Date().getTime();

                    if (earlierDebutDate && !(moment().isSame(earlierDebutDate, 'day') || moment(today).isAfter(earlierDebutDate))) {
                        Cookies.set('meStudentLoginError', startAccessError, { expires: 1 });
                        Cookies.remove('token');
                        props.history.push('/');
                    }

                    if (lastEndDate && !moment(today).isBefore(moment(lastEndDate))) {
                        Cookies.set('meStudentLoginError', endAccessError, { expires: 1 });
                        Cookies.remove('token');
                        props.history.push('/');
                    }

                    if (Cookies.get('ipUsed') && infoStudent && infoStudent.id) {
                        addOrUpdateIpUsed({
                            variables: {
                                id: infoStudent.id,
                                ipUsed: Cookies.get('ipUsed')
                            }
                        })
                    }


                } else {
                    Cookies.remove('token');
                    props.history.push('/')
                }
            },
            onError(error) {
                // console.log(error.graphQLErrors[0].message);
                if (error.graphQLErrors[0]) {
                    actions.setMeStudentLoginError(error.graphQLErrors[0].message);
                }

                Cookies.remove('token');
                props.history.push('/')
            }
        });

    // useSubscription(STUDENT_SESSION_UPDATED,
    //     {
    //         skip: state.userInfos.id == null,
    //         variables: { id: state.userInfos.id },
    //         onSubscriptionData(data) {
    //             console.log(data);
    //             if (data.subscriptionData) {
    //                 const currentToken = Cookies.get('token');
    //                 const updatedToken = data.subscriptionData.data.studentSessionUpdated.session;

    //                 setTimeout(() => {
    //                     NotificationManager.error(state.lang === 'fr' ? "Une session sur un autre appareil ou navigateur a été lancée. Vous allez être déconnecté dans quelques secondes" : 'A session on another device or browser has been initiated. You will be logged out in a few seconds', state.lang === 'fr' ? 'Déconnection en cours!' : 'Logging out!', 6000);
    //                 }, 1000);

    //                 if (updatedToken !== currentToken) {
    //                     setTimeout(() => {
    //                         logout();
    //                     }, 7000);
    //                 }
    //             }
    //         },
    //         onError(err) {
    //             console.log(err)
    //         }
    //     }
    // );


    const [addOrUpdateIpUsed] = useMutation(
        ADD_OR_UPDATE_IP_USED,
        {
            onCompleted(response) {
                console.log(response.addOrUpdateIpUsed)
            },
            onError({ response, operation, graphQLErrors }) {
                console.log(response);
                console.log(graphQLErrors);
            }
        }
    );


    const logout = () => {
        Cookies.remove('token');
        props.history.push('/');
        refreshPage()
    };

    const refreshPage = () => {
        window.location.reload(false);
    };

    useEffect(() => {
        setNavElements({
            home: {
                id: 'home',
                label: t('menu-home'),
                path: '/accueil'
            },
            myCourses: {
                id: 'myCourses',
                label: t('menu-courses'),
                path: '/liste-formations'
            },
            contact: {
                id: 'contact',
                label: t('menu-contact'),
                path: '/contact'
            },
            help: {
                id: 'help',
                label: t('menu-help'),
                path: '/info'
            },
        });
        setupGreetingMsg();
        setSearchPlaceholder(t('menu-search-placeholder'));

    }, [state.lang]);


    useEffect(() => {
        if (state.userInfos.firstName && state.pageName === 'home') {
            setTimeout(() => {
                if (window.navigator.userAgent.indexOf('Trident') !== -1) {
                    NotificationManager.warning(t('dashboardContainerWarningBetterExperience'), 'Suggestion !', 50000)
                } else {
                    NotificationManager.info(t('dashboardContainerHaveAGoodSession'), greetingMsg + ' ' + state.userInfos.firstName, 10000)
                }
            }, 1000);
        }

        if (state.userInfos.firstName) {
            const timerInterval = setInterval(() => {
                ReactGA.send('pageview', { path: window.location.pathname + window.location.search + '&userName=' + (state.userInfos.firstName + '_' + state.userInfos.lastName) });

                ReactGA.event({
                    category: 'CoursePay',
                    params: {
                        action: state.userInfos.firstName + '_' + state.userInfos.lastName,
                        label: window.location.search,
                    }
                });
            }, 3000);

            return () => clearInterval(timerInterval);
        }

    }, [state.userInfos.firstName, state.pageName]);

    const setupGreetingMsg = () => {
        let hr = currentTime.getHours();

        if ((hr === 23) || (hr === 0) || (hr === 1) || (hr === 2) || (hr === 3) || (hr === 4)) {
            setGreetingMsg(t('dashboardContainerGoodNight'));
        }
        if ((hr === 5) || (hr === 6) || (hr === 7)) {
            setGreetingMsg(t('dashboardContainerGoodMorning'));
        }
        if ((hr === 8) || (hr === 9)) {
            setGreetingMsg(t('dashboardContainerHello'))
        }
        if ((hr === 10) || (hr === 11)) {
            setGreetingMsg(t('dashboardContainerGoodMorning2'));
        }
        if (hr === 12) {
            setGreetingMsg(t('dashboardContainerGoodLunch'));
        }
        if ((hr === 13) || (hr === 14) || (hr === 15) || (hr === 16)) {
            setGreetingMsg(t('dashboardContainerGoodAfterNoon'));
        }
        if ((hr === 17) || (hr === 18) || (hr === 19) || (hr === 20) || (hr === 21) || (hr === 22)) {
            setGreetingMsg(t('dashboardContainerGoodEvening'));
        }
    };

    const handleBurgerClick = (e) => {
        if (burgerClass.indexOf('is-active') !== -1) {
            setBurgerClass('hamburger hamburger--collapse');
            setMobileMenuClass('menu-mobile');
        } else {
            setBurgerClass('hamburger hamburger--collapse is-active');
            setMobileMenuClass('menu-mobile menu-mobile-open');
        }

    };

    const changeLangHandler = (e) => {
        e.preventDefault();
        if (state.lang === "fr") {
            actions.changeLang('en')
        } else {
            actions.changeLang('fr')
        }
    };

    const setVisitInfoPageCoookie = () => {
        Cookies.set('infoPageVisited', true, { expires: 365 });
    };

    if (loading || error) return <div className="fallback-loader"><MoonLoader color={'#cd1236'} /></div>;

    return (
        <div>
            <NotificationContainer />
            <div className={"menu-top-container"}>

                <div className={"menu-top-bar"}>
                    <Link to={'/accueil'}>
                        <div className={'logo'}>
                            <img src={state.imagesSourcesFilesUrl + "icite-logo.png"} alt="logo" />
                        </div>
                    </Link>
                    <nav className={'menu-desktop'}>
                        <ul>
                            {
                                Object.keys(navElements).map(function (key, index) {
                                    if (key === 'profil') return;

                                    return (
                                        <li key={'li-' + key}><Link to={navElements[key].path}>{navElements[key].label}</Link></li>
                                    )
                                })
                            }
                        </ul>
                        {/* <Form inline onSubmit={handleSearchSubmit} className={'search-form'}>
                            <FontAwesomeIcon className={'search-icon'} icon={faSearch} />
                            <FormControl
                                type="text"
                                id={'MenuSearch'}
                                className="search-input"
                                placeholder={searchPlaceholder}
                                onKeyUp={handleSearch}
                                onFocus={clearPlaceholder}
                                onBlur={putPlaceholder} />
                        </Form> */}
                        <div className={"profil-btn"}>
                            <Link to={'/profil'}><span className={'profile-icon-cnt'}><FontAwesomeIcon className={'profile-icon'} icon={faUser} /></span></Link>

                        </div>

                        {
                            state.userInfos.hasAdminAccount ? (
                                <div className={"admin-btn"}>
                                    <a href={'https://icite.intactfc.com/?loginAdmin=true'}><span className={'admin-icon-cnt'}>Admin <FontAwesomeIcon className={'profile-icon'} icon={faChalkboardTeacher} /></span></a>
                                </div>
                            ) : null
                        }
                        <div className={"lang-btn"}>
                            <a href="#" className="lang-selector" onClick={changeLangHandler}>{t('dashboardContainerFr/Eng')}</a>
                        </div>
                    </nav>
                    <nav className={mobileMenuClass}>
                        <ul>
                            {
                                Object.keys(navElements).map(function (key, index) {
                                    return (
                                        <li key={'li-' + key}><Link to={navElements[key].path} onClick={handleBurgerClick}>{navElements[key].label}</Link></li>
                                    )
                                })
                            }

                            <br />
                            <li className={"menu-btn-style menu-btn-lang-switch"} onClick={changeLangHandler} ><span>{t('dashboardContainerFr/Eng')}</span></li>
                            <br />
                            <li onClick={logout} ><span> {t('dashboardContainerLogout')}</span></li>
                        </ul>
                    </nav>
                    <button className={burgerClass} onClick={handleBurgerClick} type="button">
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>

            {/*************PAGE CONTENT***********/}
            {props.component}
            {/*************PAGE CONTENT***********/}

            <InfoPageCenteredModal
                show={infoPageModalShow}
                onHide={() => {
                    setInfoPageModalShow(false);
                    setVisitInfoPageCoookie()
                }}
            />
        </div>
    );

};

export default DashboardContainer;
