
const cmsGetPathCourseListLimited = (cmsBaseURL, courseTagList) => {
    return cmsBaseURL + '/items/model_courses?filter[course_tag][_in]=' + courseTagList + '&sort=course_title&fields=' +
        'status,' +
        'module_type,' +
        'course_title,' +
        'course_tag,' +
        'course_description,' +
        'thumbnail.*,' +
        'time_length,' +
        'date_created,' +
        'ufc&cb=' + Date.now()
};

const cmsGetPathCourseListLimitedGraphql = (courseTagList) => {
    return {
        query: `
      query {
        model_courses(limit: -1,sort: "course_title", filter: { course_tag: { _in: ${JSON.stringify(courseTagList)} }} ){
            status
            course_title
            course_tag
            course_description
            thumbnail{
                id
            }
            time_length
            date_created
            ufc
        }
      }
    `}
};



const cmsGetPathCourseList = (cmsBaseURL) => {
    return cmsBaseURL + '/items/model_courses?sort=course_title&fields=' +
        'status,' +
        'module_type,' +
        'course_title,' +
        'course_tag,' +
        'course_description,' +
        'thumbnail.*,' +
        'time_length,' +
        'date_created,' +
        'ufc&cb=' + Date.now()
};

const cmsGetPathCourseListGraphql = {
    query: `
      query {
        model_courses(limit: -1,sort: "course_title") {
            status
            course_title
            course_tag
            course_description
            thumbnail{
                id
            }
            time_length
            date_created
            ufc
        }
      }
    `
};

const cmsGetPathCourseListDeepSearch = (cmsBaseURL) => {
    return cmsBaseURL + '/items/model_courses?deep[courses_modules][_limit]=-1&sort=course_title&fields=' +
        'status,' +
        'module_type,' +
        'course_types.course_types_id.course_type,' +
        'courses_modules.modules_id.*.*,' +
        'course_title,' +
        'course_tag,' +
        'course_description,' +
        'thumbnail.*,' +
        'time_length,' +
        'date_created,' +
        'ufc&cb=' + Date.now()
};

const modulesTypeFieldGetter = {
    'video': 'video_type_id',
    'exercice': 'exercice_type_id',
    'exam': 'exam_type_id',
    'exammultiplechoice': 'exam_multiple_choice_type_id',
    'scorm': 'scorm_type_id',
    'dndtext': 'drag_and_drop_text_type_id',
    'dndmultiple': 'drag_and_drop_text_multiple_targets_type_id',
    'dndimages': 'drag_and_drop_images_type_id',
    'calculation': 'calculation_type_id',
    'rafale': 'rafale_type_id',
    'documents': 'documents_type_id',
    'html': 'html_type_id',
    'exerciceopenquestion': 'exercice_open_question_type_id',
    'exercicemultiplechoices': 'exercice_multiple_choices_type_id',
    'start': 'start_type_id',
    'end': 'end_type_id',
    'division': 'division_type_id',
    'autoevaluation1': 'auto_evaluation_1_type_id',
}





// Now replace the placeholder in the base query with the dynamically generated fields.

const cmsGetPathCourseListDeepSearchGraphql = (courseTagList) => {

    const baseQuery = `
  query {
    model_courses(limit: -1,sort: "course_title"${courseTagList && courseTagList.length > 0 ? `, filter: { course_tag: { _in: ${JSON.stringify(courseTagList)} }}` : ""} ){
      status
      course_title
      course_tag
      course_description
      thumbnail {
        id
      }
      time_length
      date_created
      ufc
      course_types {
        course_types_id {
          course_type
        }
      }
      courses_modules(limit: -1) {
        sort
        modules_id {
          title
          type
          $insertModuleFieldsHere
        }
      }
    }
  }
`;

    let moduleFields = "";

    Object.entries(modulesTypeFieldGetter).forEach(([moduleName, fieldId]) => {
        moduleFields += `
        module_${moduleName}_content {
          ${fieldId} {
            id
          }
        }
      `;
    });

    const completeQuery = baseQuery.replace("$insertModuleFieldsHere", moduleFields);


    return {
        query: completeQuery
    }
};



const cmsGetPathCoursePlay = (cmsBaseURL, courseTag) => {
    return cmsBaseURL + '/items/model_courses?deep[courses_modules][_limit]=-1&filter[course_tag][_eq]=' + courseTag + '&fields=' +
        'thumbnail.*,' +
        'banner.*,' +
        'time_length,' +
        'documents.*.*.*,' +
        'course_types.course_types_id.course_type,' +
        'courses_modules.*,' +
        'courses_modules.modules_id.*,' +
        'courses_modules.modules_id.module_video_content.video_type_id.*,' +
        'courses_modules.modules_id.module_video_content.video_type_id.upload_video.*,' +
        'courses_modules.modules_id.module_scorm_content.scorm_type_id.*,' +
        'courses_modules.modules_id.module_scorm_content.scorm_type_id.scorm_versions.scorm_versions_id.version,' +
        'courses_modules.modules_id.module_scorm_content.scorm_type_id.scorm_versions.scorm_versions_id.scorms.scorm_files_id.*,' +
        'courses_modules.modules_id.module_scorm_content.scorm_type_id.scorm_versions.scorm_versions_id.scorms.scorm_files_id.scorm_file.*,' +
        'courses_modules.modules_id.module_exercice_content.exercice_type_id.*,' +
        'courses_modules.modules_id.module_exercice_content.exercice_type_id.question.sort,' +
        'courses_modules.modules_id.module_exercice_content.exercice_type_id.question.exercice_question_id.*,' +
        'courses_modules.modules_id.module_exercice_content.exercice_type_id.question.exercice_question_id.other_image.*,' +
        'courses_modules.modules_id.module_exam_content.exam_type_id.*,' +
        'courses_modules.modules_id.module_exam_content.exam_type_id.question.sort,' +
        'courses_modules.modules_id.module_exam_content.exam_type_id.question.exam_question_id.*,' +
        'courses_modules.modules_id.module_exam_content.exam_type_id.question.exam_question_id.other_image.*,' +
        'courses_modules.modules_id.module_exammultiplechoice_content.exam_multiple_choice_type_id.*,' +
        'courses_modules.modules_id.module_exammultiplechoice_content.exam_multiple_choice_type_id.question.sort,' +
        'courses_modules.modules_id.module_exammultiplechoice_content.exam_multiple_choice_type_id.question.exam_multiple_choice_question_id.*,' +
        'courses_modules.modules_id.module_exammultiplechoice_content.exam_multiple_choice_type_id.question.exam_multiple_choice_question_id.other_image.*,' +
        'courses_modules.modules_id.module_dndtext_content.drag_and_drop_text_type_id.*,' +
        'courses_modules.modules_id.module_dndtext_content.drag_and_drop_text_type_id.image_title.*,' +
        'courses_modules.modules_id.module_dndtext_content.drag_and_drop_text_type_id.question.sort,' +
        'courses_modules.modules_id.module_dndtext_content.drag_and_drop_text_type_id.question.drag_and_drop_text_question_id.*,' +
        'courses_modules.modules_id.module_dndmultiple_content.drag_and_drop_text_multiple_targets_type_id.*,' +
        'courses_modules.modules_id.module_dndmultiple_content.drag_and_drop_text_multiple_targets_type_id.image_title.*,' +
        'courses_modules.modules_id.module_dndmultiple_content.drag_and_drop_text_multiple_targets_type_id.question.sort,' +
        'courses_modules.modules_id.module_dndmultiple_content.drag_and_drop_text_multiple_targets_type_id.question.drag_and_drop_text_multiple_targets_question_id.*,' +
        'courses_modules.modules_id.module_dndimages_content.drag_and_drop_images_type_id.*,' +
        'courses_modules.modules_id.module_dndimages_content.drag_and_drop_images_type_id.image_title.*,' +
        'courses_modules.modules_id.module_dndimages_content.drag_and_drop_images_type_id.question.sort,' +
        'courses_modules.modules_id.module_dndimages_content.drag_and_drop_images_type_id.question.drag_and_drop_images_question_id.*,' +
        'courses_modules.modules_id.module_dndimages_content.drag_and_drop_images_type_id.question.drag_and_drop_images_question_id.choices.drag_and_drop_images_single_choices_id.*.*,' +
        'courses_modules.modules_id.module_calculation_content.calculation_type_id.*,' +
        'courses_modules.modules_id.module_calculation_content.calculation_type_id.image_title.*,' +
        'courses_modules.modules_id.module_calculation_content.calculation_type_id.question.sort,' +
        'courses_modules.modules_id.module_calculation_content.calculation_type_id.question.calculation_question_id.*,' +
        'courses_modules.modules_id.module_rafale_content.rafale_type_id.*,' +
        'courses_modules.modules_id.module_rafale_content.rafale_type_id.image_title.*,' +
        'courses_modules.modules_id.module_rafale_content.rafale_type_id.question.sort,' +
        'courses_modules.modules_id.module_rafale_content.rafale_type_id.question.rafale_question_id.*,' +
        'courses_modules.modules_id.module_exerciceopenquestion_content.exercice_open_question_type_id.*,' +
        'courses_modules.modules_id.module_exerciceopenquestion_content.exercice_open_question_type_id.question.sort,' +
        'courses_modules.modules_id.module_exerciceopenquestion_content.exercice_open_question_type_id.question.exercice_open_question_id.*,' +
        'courses_modules.modules_id.module_exerciceopenquestion_content.exercice_open_question_type_id.question.exercice_open_question_id.other_image.*,' +
        'courses_modules.modules_id.module_exercicemultiplechoices_content.exercice_multiple_choices_type_id.*,' +
        'courses_modules.modules_id.module_exercicemultiplechoices_content.exercice_multiple_choices_type_id.question.sort,' +
        'courses_modules.modules_id.module_exercicemultiplechoices_content.exercice_multiple_choices_type_id.question.exercice_multiple_choices_question_id.*,' +
        'courses_modules.modules_id.module_exercicemultiplechoices_content.exercice_multiple_choices_type_id.question.exercice_multiple_choices_question_id.other_image.*,' +
        'courses_modules.modules_id.module_documents_content.documents_type_id.*,' +
        'courses_modules.modules_id.module_documents_content.documents_type_id.documents.*.*.*,' +
        'courses_modules.modules_id.module_html_content.html_type_id.*,' +
        'courses_modules.modules_id.module_start_content.start_type_id.*,' +
        'courses_modules.modules_id.module_end_content.end_type_id.*,' +
        'courses_modules.modules_id.module_division_content.division_type_id.*,' +
        'courses_modules.modules_id.module_autoevaluation1_content.auto_evaluation_1_type_id.*,' +
        'courses_modules.modules_id.module_autoevaluation1_content.auto_evaluation_1_type_id.question.sort,' +
        'courses_modules.modules_id.module_autoevaluation1_content.auto_evaluation_1_type_id.question.auto_evaluation_1_question_id.*,' +
        'courses_modules.modules_id.module_autoevaluation1_content.auto_evaluation_1_type_id.question.auto_evaluation_1_question_id.other_image.*,' +
        'courses_modules.modules_id.module_autoevaluation1_content.auto_evaluation_1_type_id.question.auto_evaluation_1_question_id.choices.auto_evaluation_1_choices_id.*,' +
        '*&cb=' + Date.now()
};

const cmsGetPathCoursePlayGraphql = (courseTag) => {
    return {
        query: `
        query {
            model_courses(limit: -1,filter: { course_tag: { _eq: "${courseTag}" } }) {
                time_length
                course_title
                course_tag
                ufc
                id
                course_description
                reading_mode
                request_meeting_mode
                meetings_standard_title
                meetings_standard_description
                activity_qty
                banner_black_layer
                studentGuideButtonDisabled
                chatBotQuestions
                chatBotActivated
                chatBotEmailRecipients
                required_meeting_mode_internal
                required_meeting_mode_external
                reading_mode_external
                reading_mode_internal
                banner_full_height
                documents_url_external
                deeplink_access_internal
                deeplink_access_external
                enable_modules_requirements
                modules_requirements_conditions
                status
                enable_filters
                filter_type_name_fr
                filter_type_name_en
                filters
                thumbnail {
                    id
                }
                banner {
                    id
                }
                documents {
                    id
                    directus_files_id {
                        id
                        title
                        filename_download
                        full_url
                    }
                }
                course_types {
                    course_types_id {
                        course_type
                    }
                }
                courses_modules(limit: -1){
                    sort
                    modules_id {
                        id
                        title
                        type
                        display_additional_information
                        additional_information
                        module_video_content {
                            video_type_id {
                                id
                                title
                                time
                                keep_on_going
                                upload_video {
                                    id
                                }
                            }
                        }
                        module_scorm_content {
                            scorm_type_id {
                                id
                                title
                                description
                                time
                                hide_all_existing_button
                                hide_percentage_score
                                scorm_versions {
                                    sort
                                    scorm_versions_id {
                                        version
                                        sort
                                        scorms {
                                            scorm_files_id {
                                                scorm_title
                                                export_from
                                                redo_option
                                                scorm_file {
                                                    id
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        module_exercice_content{
                            exercice_type_id{
                                id
                                title
                                time
                                exercice_tag
                                chrono_questions_time
                                consigne
                                keep_on_going
                                questions_chrono_activated
                                question{
                                    sort
                                    exercice_question_id{
                                        id
                                        question
                                        choice_a
                                        choice_b
                                        choice_c
                                        choice_d
                                        good_choice
                                        choice_feedback
                                        choice_feedback_negative
                                        other_image{
                                            id 
                                        }
                                    }
                                }
                            }
                        }
                        module_exam_content{
                            exam_type_id{
                                id
                                title
                                duration
                                timer_active
                                passing_score
                                oneTentativeOnly
                                exam_tag
                                question{
                                    sort
                                    exam_question_id{
                                        id
                                        sort
                                        choice_feedback
                                        good_choice
                                        question
                                        choice_a
                                        choice_b
                                        choice_c
                                        choice_d
                                        other_image{
                                            id
                                        }
                                    }
                                }
                            }
                        }
                        module_exammultiplechoice_content{
                            exam_multiple_choice_type_id{
                                id
                                title
                                duration
                                timer_active
                                passing_score
                                exam_tag
                                ponderation
                                question{
                                    sort
                                    exam_multiple_choice_question_id{
                                        id
                                        sort
                                        choices
                                        choice_feedback
                                        question
                                        points
                                        other_image
                                    }
                                }
                            }
                        }
                        module_dndtext_content{
                            drag_and_drop_text_type_id{
                                id
                                exercice_tag
                                title
                                time
                                instructions
                                image_title{
                                    id
                                }
                                question{
                                    sort
                                    drag_and_drop_text_question_id{
                                        id
                                        sort
                                        question
                                        choices
                                        good_choice
                                        choice_feedback
                                    }
                                }
                            }
                        }
                        module_dndmultiple_content{
                            drag_and_drop_text_multiple_targets_type_id{
                                id
                                title
                                time
                                instructions
                                exercice_tag
                                image_title{
                                    id
                                }
                                question{
                                    sort
                                    drag_and_drop_text_multiple_targets_question_id{
                                        id
                                        sort
                                        choices_associations
                                        choice_feedback
                                        choices
                                        question
                                    }
                                }
                            }
                        }
                        module_dndimages_content{
                            drag_and_drop_images_type_id{
                                id
                                title
                                time
                                instructions
                                exercice_tag
                                image_title{
                                    id
                                }
                                question{
                                    sort
                                    drag_and_drop_images_question_id{
                                        id
                                        choice_feedback
                                        question
                                        choices{
                                            sort
                                            drag_and_drop_images_single_choices_id{
                                                id
                                                good_answer
                                                text
                                                image{
                                                    id
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        module_calculation_content{
                            calculation_type_id{
                                id
                                title
                                time
                                exercice_tag
                                instructions
                                image_title{
                                    id
                                }
                                question{
                                    sort
                                    calculation_question_id{
                                        id
                                        good_answer
                                        choice_feedback
                                        question_context
                                        question
                                    }
                                }
                            }
                        }
                        module_rafale_content{
                            rafale_type_id{
                                id
                                title
                                time
                                exercice_tag
                                goal_points
                                image_title{
                                    id
                                }
                                question{
                                    sort
                                    rafale_question_id{
                                        id
                                        points
                                        choice_a
                                        choice_b
                                        choice_c
                                        choice_d
                                        good_choice
                                        choice_feedback
                                        question
                                    }
                                }
                            }
                        }
                        module_exerciceopenquestion_content{
                            exercice_open_question_type_id{
                                id
                                title
                                time
                                keep_on_going
                                consigne
                                force_answering_ordered
                                exercice_tag
                                question{
                                    sort
                                    exercice_open_question_id{
                                        id
                                        answersTypes
                                        customRadioBtnImagesType
                                        question_category
                                        intro_choices
                                        answer_feedback
                                        good_answer
                                        question
                                        other_image{
                                            id
                                        }
                                    }
                                }
                            }
                        }
                        module_exercicemultiplechoices_content{
                            exercice_multiple_choices_type_id{
                                id
                                title
                                time
                                goodAnswersRequiredToContinue
                                consigne
                                exercice_tag
                                question{
                                    sort
                                    exercice_multiple_choices_question_id{
                                        id
                                        question
                                        choices
                                        choice_feedback
                                        choice_feedback_negative
                                        question_category
                                        singleChoice
                                        other_image{
                                            id
                                        }
                                    }
                                }
                            }
                        }
                        module_documents_content{
                            documents_type_id{
                                id
                                title
                                time
                                completionOnNextButtonClick
                                customNextButtonText
                                text_box_2
                                text_box
                                documents{
                                    id
                                    directus_files_id {
                                        id
                                        title
                                        filename_download
                                        full_url
                                    }
                                }
                            }
                        }
                        module_html_content{
                            html_type_id{
                                id
                                title
                                time
                                completionOnNextButtonClick
                                customNextButtonText
                                html_content
                                id
                            }
                        }
                        module_start_content{
                            start_type_id{
                                id
                                title
                                time
                                id
                                html_content
                                banner_image{
                                    id
                                }
                                display_banner_title
                                banner_container_bg_color
                            }
                        }
                        module_end_content{
                            end_type_id{
                                id
                                title
                                time
                                id
                                content
                            }
                        }
                        module_division_content{
                            division_type_id{
                                id
                                title
                                id
                                sub_division
                                content
                                video
                            }
                        }
                        module_autoevaluation1_content{
                            auto_evaluation_1_type_id{
                                id
                                title
                                time
                                exercice_tag
                                question{
                                    sort
                                    auto_evaluation_1_question_id{
                                        id
                                        question
                                        choices{
                                            auto_evaluation_1_choices_id{
                                                id
                                                choice
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `}
};


export {
    cmsGetPathCourseListLimited,
    cmsGetPathCourseListLimitedGraphql,
    cmsGetPathCourseList,
    cmsGetPathCourseListGraphql,
    cmsGetPathCourseListDeepSearch,
    cmsGetPathCourseListDeepSearchGraphql,
    cmsGetPathCoursePlay,
    cmsGetPathCoursePlayGraphql
};