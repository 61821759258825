import React, { useState } from 'react';
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import MessageParser from './MessageParser.jsx';
import ActionProvider from './ActionProvider.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltDown, faX, faRobot, faCompressArrowsAlt, faExpand } from '@fortawesome/free-solid-svg-icons';
import { createChatBotMessage } from 'react-chatbot-kit';
import DogPicture from './widgets/DogPicture';
import Overview from "./widgets/Overview";
import MoreQuestionsYesOrNo from "./widgets/MoreQuestionsYesOrNo";
import Satisfaction from "./widgets/Satisfaction";
import AnswerCnt from "./widgets/AnswerCnt";
import "./ChatBotIcite.scss"


function ChatBotIcite(props) {

    const [expandedChatBox, setExpandedChatBox] = useState(false);

    function getMultipleRandom(arr, num) {
        const shuffled = [...arr].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, num);
    }

    const config = {

        initialMessages: [
            createChatBotMessage(props.lang === 'fr' ? `Bonjour ${props.userInfos.firstName}, je suis enchantée de vous voir ici` : `Hello ${props.userInfos.firstName}, it's nice to see you here`),
            createChatBotMessage(props.lang === 'fr' ? "Laissez-moi me présenter, je suis Elise, votre tuteur virtuel, ça me fera plaisir de répondre à vos questions aujourd’hui" : "Let me introduce myself, I am Elise, your virtual tutor, I will be happy to answer your questions today", {
                delay: 2000,
            }),
            createChatBotMessage(props.lang === 'fr' ? "Veuillez écrire votre question ou sélectionner une des questions de l’exemple ci-dessous." : "Please write your question or select one of the sample questions below.", {
                delay: 5000,
                widget: "overview"
            })
        ],
        botName: "ELISE",
        customStyles: {
            botMessageBox: {
                backgroundColor: '#376B7E',
            },
            chatButton: {
                backgroundColor: '#00b3be',
            },
        },
        widgets: [
            {
                widgetName: 'dogPicture',
                widgetFunc: (props) => <DogPicture {...props} />,
            },
            {
                widgetName: 'overview',
                widgetFunc: (props) => <Overview {...props} />,
                mapStateToProps: ["messages", "actions"]
            },
            {
                widgetName: 'moreQuestionsYesOrNo',
                widgetFunc: (props) => <MoreQuestionsYesOrNo {...props} />,
                mapStateToProps: ["messages", "actions"]
            },
            {
                widgetName: 'satisfaction',
                widgetFunc: (props) => <Satisfaction {...props} />,
                mapStateToProps: ["messages", "actions"]
            },
            {
                widgetName: 'answerCnt',
                widgetFunc: (props) => <AnswerCnt {...props} />,
                mapStateToProps: ["messages", "actions"]
            }
        ],
        customComponents: {
            botAvatar: (props) => <div className={"chatbot-avatar"}>
                <div className={"chatbot-avatar-icon-cnt"}>
                    <FontAwesomeIcon icon={faRobot} className={"chatbot-avatar-icon"} />
                </div>
                {/* <p>Question ?</p> */}
            </div>,
        },
    };

    return (
        <div className={"chatbot-main-cnt" + " " + (expandedChatBox ? "chatBot-is-expanded" : "")}>

            <div className={"chatbot-extra-buttons-cnt"}>
                <FontAwesomeIcon title="Agrandir/réduire la fenêtre" icon={expandedChatBox ? faCompressArrowsAlt : faExpand} className={"chatbot-expand-btn"} onClick={() => setExpandedChatBox(prev => !prev)} />
                <FontAwesomeIcon title="Masquer la conversation" icon={faLongArrowAltDown} className={"chatbot-minmax-btn"} onClick={() => props.handleMinMaxChatBotBox(prev => !prev)} />
                <FontAwesomeIcon title="Quitter le Chatbot" icon={faX} className={"chatbot-close-btn"} onClick={() => props.handleOpenCloseChatbot()} />
            </div>

            <Chatbot
                config={{
                    ...config,
                    state: {
                        overviewQuestions: getMultipleRandom(props.chatBotQuestions, 3),
                        chatBotQuestions: props.chatBotQuestions,
                        chatBotEmailRecipients: props.chatBotEmailRecipients,
                        courseName: props.courseName,
                        userInfos: props.userInfos,
                        consecutiveUnansweredQuestion: 0,
                        lang: props.lang
                    }
                }}
                messageParser={MessageParser}
                actionProvider={ActionProvider}
                headerText='ELISE'
                placeholderText={props.lang === 'fr' ? 'Veuillez poser une question ici' : 'Please ask your question here'}
            />

        </div>
    );
}

export default ChatBotIcite;
