import React, { Suspense, useEffect, useState } from 'react';
import { lazy } from '@loadable/component'
import { AppContext, useAppState } from './useAppState'
import './App.scss';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MoonLoader from 'react-spinners/MoonLoader';
import { client } from './apollo';
import { AuthenticatedRoute } from "./helpers/authenticatedRoute";
import CoursePlay from './pages/CoursePlay/CoursePlay';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

// MODALE CHANGEMENT DE NOM DE DOMAINE
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faInfoCircle, faStar } from '@fortawesome/free-solid-svg-icons';


const Login = lazy(() => import('./pages/Login/Login'));
const LoginSSO = lazy(() => import('./pages/Login/LoginSSO'));
const Home = lazy(() => import('./pages/Home/Home'));
const CoursesList = lazy(() => import('./pages/CoursesList/CoursesList'));
const Profile = lazy(() => import('./pages/Profile/Profile'));
const Contact = lazy(() => import('./pages/Contact/Contact'));
const Help = lazy(() => import('./pages/Help/Help'));
const ProfileResetPassword = lazy(() => import('./pages/Profile/ProfileResetPassword/ProfileResetPassword'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword/ResetPassword'));


function App() {

    const { state, actions } = useAppState();

    /*****************************************
        MODALE CHANGEMENT NOM DE DOMAINE
    ******************************************/
    // const [showModal, setShowModal] = useState(false);
    // useEffect(() => {
    //     const startDateModal = new Date('2024-07-24'); // Date de début d'affichage de la modale
    //     const cookie = localStorage.getItem('modalShown');
    //     const now = new Date();
    //     if (now >= startDateModal && (!cookie || new Date(cookie) >= Date.now() - 7 * 24 * 60 * 60 * 1000)) {
    //         setShowModal(true);
    //         localStorage.setItem('modalShown', new Date(), { expires: 7 });
    //     }
    // }, []);

    return (
        <ApolloProvider client={client}>
            <AppContext.Provider value={{ state, actions }} >
                <Router basename={state.baseName}>
                    <div className="App">
                        <Suspense fallback={<div className="fallback-black-loader"><MoonLoader color={'#cd1236'} /></div>}>



                            {/*****************************************
                                MODALE CHANGEMENT NOM DE DOMAINE
                            ******************************************/}
                            {/* <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" dialogClassName="modal-dialog modal-lg modal-dialog-centered" style={{ display: showModal ? 'block' : 'none', zIndex: '100000' }} centered>
                                <Modal.Header>
                                    <Modal.Title style={{ color: '#c60c30' }}><FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '8px' }} />
                                        {state.lang === 'fr' ? (
                                            <>
                                                Information
                                            </>
                                        ) : (
                                            <>
                                                Information
                                            </>)}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ minHeight: '250px', paddingRight: '80px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    {state.lang === 'fr' ? (
                                        <>
                                            <p><strong style={{ fontSize: '1.1em' }}>Mettez à jour vos favoris!</strong></p>
                                            <p>Supprimez votre favori vers iCité.ca et recréez-le dès maintenant avec l'adresse suivante : https://icite.intactfc.com.</p>
                                            <p>Astuce : Cliquez sur l'étoile (<FontAwesomeIcon icon={faStar} style={{ margin: '0px 5px 0 5px', color: 'blue' }} />) de votre barre de navigation pour mettre l'adresse à jour.</p>
                                            <br />
                                            <p>Merci de votre compréhension.</p>
                                        </>
                                    ) : (
                                        <>
                                            <p><strong style={{ fontSize: '1.1em' }}>Update your favorites!</strong></p>
                                            <p>Delete your favorite to iCité.ca and recreate it now with the following address: https://icite.intactfc.com.</p>
                                            <p>Tip: Click on the star (<FontAwesomeIcon icon={faStar} style={{ margin: '0px 5px 0 5px', color: 'blue' }} />) in your navigation bar to update the address.</p>
                                            <br />
                                            <p>Thank you for your understanding.</p>
                                        </>
                                    )}
                                </Modal.Body>
                                <Modal.Footer className="justify-content-end" style={{ textAlign: 'center' }}>
                                    <Button onClick={() => setShowModal(false)}>
                                        {state.lang === 'fr' ? (
                                            <>
                                                J'ai compris
                                            </>
                                        ) : (
                                            <>
                                                Understood
                                            </>)}
                                        <FontAwesomeIcon style={{ marginLeft: '8px' }} icon={faCheck} />
                                    </Button>
                                </Modal.Footer>
                            </Modal> */}
                            {/*********************************************
                                END MODALE CHANGEMENT NOM DE DOMAINE
                            *********************************************/}



                            <Switch>
                                <AuthenticatedRoute path="/accueil" component={Home} />
                                <AuthenticatedRoute path="/liste-formations" component={CoursesList} />
                                <AuthenticatedRoute path="/formation" component={CoursePlay} />
                                <AuthenticatedRoute path="/formation-sc" component={CoursePlay} />
                                <AuthenticatedRoute exact path="/contact" component={Contact} />
                                <AuthenticatedRoute exact path="/info" component={Help} />
                                <AuthenticatedRoute exact path="/profil" component={Profile} />
                                <AuthenticatedRoute path="/profil/reinitialiser-mot-de-passe" component={ProfileResetPassword} />
                                <Route path="/reinitialiser-mot-de-passe" component={ResetPassword} />
                                <Route path="/mot-de-passe-oublie" component={ForgotPassword} />
                                <Route path="/login-callback" component={LoginSSO} />
                                <Route path="/" component={Login} />
                            </Switch>

                        </Suspense>
                    </div>
                </Router>
            </AppContext.Provider>
        </ApolloProvider >
    );
}

export default App;




