import { gql } from "@apollo/client";




//**********************************************
// STUDENT PROGRESSION
//**********************************************


const STUDENT_PROGRESSION = gql`
  query SingleStudentProgression($studentId: ID!){
    singleStudentProgression(studentId: $studentId){
        studentId
        studentName
        progressionJson
        student{
           profile
           affiliation
           active
        }
    }
  }
`;

const SINGLE_STUDENT_PROGRESSION = gql`
  query SingleStudentsProgression($studentId: ID!, $courseName: String!){
    singleStudentsProgression(studentId: $studentId, courseName: $courseName){
        studentId
        studentName
        courseName
        progressionJson
        requestedMeetings
        student{
           profile
           affiliation
           active
        }
    }
  }
`;

//**********************************************
// STUDENT COURSE NOTES
//**********************************************


const STUDENT_COURSES_NOTES = gql`
  query SingleCoursesStudentNotes($studentId: ID!, $courseName: String!){
    singleCoursesStudentNotes(studentId: $studentId, courseName: $courseName){
        studentId
        studentName
        courseName
        notesEditorJson
        student{
           profile
           affiliation
           active
        }
    }
  }
`;



//**********************************************
// SCORM MODEL COURSE
//**********************************************

const SCORM_MODEL_COURSE = gql`
  query cmsQuery($course_tag_eq: String){
        scorm_model_courses(filter: {course_tag_eq: $course_tag_eq}){
            data{
                status
                course_title
                course_tag
                thumbnail{
                    full_url
                    thumbnails{
                        url
                    }
                }
                scorms{
                    status
                    id
                    title
                    number
                    order
                    course_id{
                        course_tag
                    }
                    scorm_file{
                        full_url
                    }
                }
            }
        }
  }
`;


const ALL_MEETING_RESERVATION = gql`
  query AllMeetingReservation($courseName: String, $fromLms: Boolean ){
    allMeetingReservation(courseName: $courseName, fromLms: $fromLms){
        id
        createdByAdminId
        title
        description
        startDateTime
        endDateTime
        meetingUrl
        studentsListJson
        maxStudents
        courseAvailability
        status
        createdAt
        updatedAt
    }
  }
`;

const ALL_NOTIFICATIONS = gql`
      query AllNotifications{   
          allNotifications{
            id
            courses
            content
            importance
            status
          }
      }
    `;

const NOTIFICATION_PER_COURSE = gql`
query NotificationPerCourse($course: String!){
  notificationPerCourse(course: $course){
    id
    courses
    content
    importance
    startDate
    endDate
    cohorts
  }
}
`;

const ALL_SONDAGES_BY_COURSE = gql`
  query AllSondagesByCourse($courseName: String!){
    allSondagesByCourse(courseName: $courseName){
      id
      title
      type
      status
      time
      courseName
      prevModuleRelated
      reportingByCohortes
      reportingGeneral
      questionsGroups{
        id
        questions
        category
        status
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      otherCustomEdits
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

const ALL_SONDAGES_ANSWERS_BY_COURSE_AND_STUDENTS = gql`
  query AllSondageAnswersbyCourseAndStudent($courseName: String!, $studentId: Int!){
    allSondageAnswersbyCourseAndStudent(courseName: $courseName, studentId: $studentId){
      id
      studentId
      studentName
      courseName
      sondageId
      questionId
      answers
      createdAt
      updatedAt
    }
  }
`;


export {
  STUDENT_PROGRESSION,
  SINGLE_STUDENT_PROGRESSION,
  STUDENT_COURSES_NOTES,
  SCORM_MODEL_COURSE,
  ALL_MEETING_RESERVATION,
  ALL_NOTIFICATIONS,
  NOTIFICATION_PER_COURSE,
  ALL_SONDAGES_BY_COURSE,
  ALL_SONDAGES_ANSWERS_BY_COURSE_AND_STUDENTS
};