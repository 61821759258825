import React from 'react';
import { useAppContext } from "../../useAppState";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "@apollo/client";
let stringSimilarity = require("string-similarity");

const SEND_CHATBOT_CONVO = gql`
  mutation SendChatBotConvo(
    $name: String!, 
    $studentEmail: String!, 
    $recipientEmails: String!, 
    $subject: String!, 
    $convo: String!
    ) {
    sendChatBotConvo(
        name: $name, 
        studentEmail: $studentEmail, 
        recipientEmails: $recipientEmails, 
        subject: $subject, 
        convo: $convo
    )
  }
`;

const SAVE_CHATBOT_CONVO = gql`
  mutation SaveChatBotConvo(
    $studentId: ID!, 
    $studentName: String!, 
    $courseName: String!, 
    $convoId: String!, 
    $messages: String!
    ) {
    saveChatBotConvo(
        studentId: $studentId, 
        studentName: $studentName, 
        courseName: $courseName, 
        convoId: $convoId, 
        messages: $messages
    )
  }
`;

const MessageParser = ({ children, actions }) => {

    const { state } = useAppContext();

    let questionsQ = children.props.state.chatBotQuestions.map(el => el.question)

    const [sendChatBotConvo] = useMutation(
        SEND_CHATBOT_CONVO,
        {
            onCompleted(response) {
                console.log(response.sendChatBotConvo);
            },
            onError({ response, operation, graphQLErrors }) {
                console.log(response);
                console.log(graphQLErrors);
            }
        }
    );

    const [saveChatBotConvo] = useMutation(
        SAVE_CHATBOT_CONVO,
        {
            onCompleted(response) {
                console.log(response.saveChatBotConvo);
            },
            onError({ response, operation, graphQLErrors }) {
                console.log(response);
                console.log(graphQLErrors);
            }
        }
    );



    const parse = (message) => {

        let bestQMatch = stringSimilarity.findBestMatch(message, questionsQ).bestMatch
        let qListIndex = questionsQ.findIndex(el => el === stringSimilarity.findBestMatch(message, questionsQ).bestMatch.target)
        // Changement RATIO : 0.45 pour 0.25
        if (bestQMatch.target && bestQMatch.rating >= 0.25) {
            actions.handleAutomaticAnswerQ({
                question: children.props.state.chatBotQuestions[qListIndex].question,
                answer: children.props.state.chatBotQuestions[qListIndex].answer
            });
        } else {
            actions.handleNoAnswer();
            console.log(children)
            console.log(children.props.state.messages)
            console.log({
                studentId: parseInt(state.userInfos.id),
                studentName: state.userInfos.firstName + " " + state.userInfos.lastName,
                courseName: children.props.state.courseName,
                convoId: children.props.state.messages[0].id.toString(),
                messages: JSON.stringify(children.props.state.messages)
            })
        }
    };

    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    parse: parse,
                    actions,
                });
            })}
        </div>
    );
};

export default MessageParser;