import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom'
import './index.scss';
import './i18n';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import { unregister } from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-9T3PNVYC73');
ReactGA.send('pageview', { path: window.location.pathname + window.location.search });

unregister();


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
