import React from 'react';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};


const dateFormat = 'YYYY/MM/DD';

const profiles = ['Souscripteur LP', 'Souscripteur LC', 'Courtier', 'Agent', 'Autre'];
const affiliations = ['Intact', 'Belairdirect', 'Courtier', 'Autre'];
const belairCoursesVisible = [
    'AMF - Automobile',
    'AMF - Habitation',
    // 'AMF - Entreprises',
    'AMF - Droit et Lois',
    // 'Autre'
];


const phoneFormat = e => {
    if (e.keyCode !== 8) {
        let val = e.target.value;
        e.target.value = val
            .replace(/\D/g, '')
            .replace(/(\d{1,3})(\d{1,3})?(\d{1,4})?/g, function (txt, f, s, t) {
                if (t) {
                    return `(${f}) ${s}-${t}`
                } else if (s) {
                    return `(${f}) ${s}`
                } else if (f) {
                    return `(${f})`
                }
            });
    }
};

const toInputUppercase = e => {
    e.target.value = ("" + e.target.value).charAt(0).toUpperCase() + e.target.value.slice(1);
};

const filterSpacer = <span style={{ marginRight: 10 }}> </span>;

const commonReportsFiltersHandler = (filterConfig, originalDataList, setDataListMethod) => {
    let filteredDataList = originalDataList;
    if (filterConfig.byTrainer.length > 0) {
        filteredDataList = filteredDataList.filter(el => el.cohortes.filter(elCohorte => elCohorte.trainerRelatedId === filterConfig.byTrainer).length > 0)
    }

    if (filterConfig.byCohorte.length > 0) {
        filteredDataList = filteredDataList.filter(el => el.cohortes.filter(elCohorte => elCohorte.cohorteId === filterConfig.byCohorte).length > 0)
    }

    if (filterConfig.byProfile.length > 0) {
        filteredDataList = filteredDataList.filter(el => el.profile === filterConfig.byProfile)
    }
    if (filterConfig.byAffiliation.length > 0) {
        filteredDataList = filteredDataList.filter(el => el.affiliation === filterConfig.byAffiliation)
    }

    if (filterConfig.byAdmin.length > 0) {
        filteredDataList = filteredDataList.filter(el => el.adminBy === filterConfig.byAdmin)
    }

    console.log(filteredDataList);
    if (filterConfig.byAdminTeam.length > 0) {
        filteredDataList = filteredDataList.filter(el => el.adminTeam === filterConfig.byAdminTeam)
    }


    console.log(filterConfig.byAdminTeam);
    console.log(filteredDataList);

    setDataListMethod(filteredDataList);

    if (
        filterConfig.byTrainer.length <= 0 &&
        filterConfig.byCohorte.length <= 0 &&
        filterConfig.byProfile.length <= 0 &&
        filterConfig.byAffiliation.length <= 0 &&
        filterConfig.byAdmin.length <= 0 &&
        filterConfig.byAdminTeam.length <= 0
    ) {
        setDataListMethod(originalDataList)
    }

    return filteredDataList
};


const removeDuplicates = (originalArray, prop) => {
    let newArray = [];
    let lookupObject = {};

    for (let i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (let i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
    return newArray;
};


const stripHtml = (html) => {
    // Create a new div element
    let temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
};


const fancyTimeFormat = (time) => {
    // Hours, minutes and seconds
    let hrs = ~~(time / 3600);
    let mins = ~~((time % 3600) / 60);
    let secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";

    if (hrs > 0) {
        ret += "" + hrs + "h " + (mins < 10 ? "0" : "");
    } else {
        ret += "" + 0 + "h " + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + "m";

    // ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    // ret += "" + secs;
    return ret;
};


const htmlDecode = (input) => {
    let e = document.createElement('textarea');
    e.innerHTML = input;
    // handle case of empty input
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
};


const mergeByProperty = (target, source, props) => {
    source.forEach(sourceElement => {
        let targetElement = target.find(targetElement => {
            let res = props.map(el => { return sourceElement[el] === targetElement[el] })
            return res.filter(resEl => resEl === false).length === 0;
        })
        targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
    })
};


const numToSSColumn = (num) => {
    let s = '', t;

    while (num > 0) {
        t = (num - 1) % 26;
        s = String.fromCharCode(65 + t) + s;
        num = (num - t) / 26 | 0;
    }
    return s || undefined;
};



export {
    formItemLayout,
    tailFormItemLayout,
    dateFormat,
    profiles,
    belairCoursesVisible,
    affiliations,
    phoneFormat,
    toInputUppercase,
    filterSpacer,
    commonReportsFiltersHandler,
    removeDuplicates,
    stripHtml,
    fancyTimeFormat,
    htmlDecode,
    mergeByProperty,
    numToSSColumn
};