import React from 'react';
import { Route } from "react-router-dom";
import DashboardContainer from "../components/DashboardContainer/DashboardContainer";
import Cookies from 'js-cookie'
import axios from "axios";

const AuthenticatedRoute = ({ component: Component, ...rest }) => {

    let connectionTokenExist = Cookies.get('token') && Cookies.get('token') !== null && Cookies.get('token') !== 'null';
    let ispCookie = Cookies.get('isp');
    let ipUsed = Cookies.get('ipUsed');

    // const BASE_CMS_URL = 'https://content-manager.icite.intactfc.com/items/model_courses?filter[status][_eq]=published&fields=course_tag,deeplink_access_internal,deeplink_access_external';
    const BASE_CMS_URL = 'https://content-manager.icite.intactfc.com/graphql';
    const PRODUCTION_SSO_URL = 'https://icite.intactfc.com/intact/login';
    const DEV_SSO_URL = 'https://icite.intactfc.com/dev/intact/login';
    const FALLBACK_URL = process.env.REACT_APP_ENV === 'production' ? 'https://icite.intactfc.com' : 'https://icite.intactfc.com/dev';
    const urlHref = window.location.href;
    const urlOrigin = window.location.origin;

    const graphQuery = {
        query: `
          query {
            model_courses(filter: { status: {_eq: "published"}}) {
                status
                course_tag
                deeplink_access_internal
                deeplink_access_external
            }
          }
        `
    };

    if (Cookies.get('loginAdmin') || Cookies.get('loginOutilsWeb')) {
        window.location.href = PRODUCTION_SSO_URL;
    }

    if (!connectionTokenExist) {
        axios.post(BASE_CMS_URL, graphQuery, {
            headers: {
                authorization: `Bearer ${process.env.REACT_APP_CMS_API_TOKEN}`
            }
        }).then(response => {
            const courses = response.data.data.model_courses;

            console.log('!connectionTokenExist - post cms response', response);


            const isInternalNetwork = (
                ['Intact Financial Corporation', 'Partner Solutions Inc'].includes(ispCookie) ||
                (ipUsed && (
                    ipUsed.includes('142.216.101') ||
                    ipUsed.includes('142.216.137') ||
                    ipUsed.includes('142.216.168') ||
                    ipUsed.includes('216.23.137.246') ||
                    ipUsed.includes('216.23.137.247') ||
                    ipUsed.includes('216.23.137')
                ))
            )

            const courseTagsToExcludeWhenDirectLink = courses
                .filter(course => {
                    console.log('course', course);
                    return (isInternalNetwork && !course.deeplink_access_internal) ||
                        (!isInternalNetwork && !course.deeplink_access_external);
                })
                .map(course => course.course_tag);

            const currentPath = urlHref.replace(urlOrigin, '');

            if (!courseTagsToExcludeWhenDirectLink.some(tag => currentPath.includes(tag))) {
                Cookies.set('directLinkPath', currentPath, { expires: 1 });
            }

            window.location.href = process.env.REACT_APP_ENV === 'production' ? PRODUCTION_SSO_URL : DEV_SSO_URL;
        }).catch(error => {
            console.log('Error fetching results ', error);
            window.location.href = FALLBACK_URL;
        });
    } else {
        setTimeout(() => {
            const currentPath = window.location.href.replace(window.location.origin, '');
            if (currentPath === Cookies.get('directLinkPath')) {
                Cookies.remove('directLinkPath');
            }
        }, 3000);
    }


    return (
        <Route
            {...rest}
            render={props =>
                connectionTokenExist ? (
                    <DashboardContainer {...props} component={<Component {...props} />} />
                ) : null
            }
        />
    );
};



export { AuthenticatedRoute };



