import React from 'react';
import { useMutation } from "@apollo/react-hooks";
import { gql } from "@apollo/client";

const SEND_CHATBOT_CONVO = gql`
  mutation SendChatBotConvo(
    $name: String!, 
    $studentEmail: String!, 
    $recipientEmails: String!, 
    $subject: String!, 
    $convo: String!
    ) {
    sendChatBotConvo(
        name: $name, 
        studentEmail: $studentEmail, 
        recipientEmails: $recipientEmails, 
        subject: $subject, 
        convo: $convo
    )
  }
`;

const SAVE_CHATBOT_CONVO = gql`
  mutation SaveChatBotConvo(
    $studentId: ID!, 
    $studentName: String!, 
    $courseName: String!, 
    $convoId: String!, 
    $messages: String!
    ) {
    saveChatBotConvo(
        studentId: $studentId, 
        studentName: $studentName, 
        courseName: $courseName, 
        convoId: $convoId, 
        messages: $messages
    )
  }
`;

const ActionProvider = ({ createChatBotMessage, setState, children }) => {

    const chatBotState = children.props.children.props.state;
    const langFr = chatBotState.lang === 'fr';
    const userEmail = chatBotState.userInfos.email;
    const userId = chatBotState.userInfos.id;
    const userFirstName = chatBotState.userInfos.firstName;
    const userLastName = chatBotState.userInfos.lastName;
    const courseName = chatBotState.courseName;
    const chatBotEmailRecipients = chatBotState.chatBotEmailRecipients;
    const chatBotMessages = chatBotState.messages;


    const [sendChatBotConvo] = useMutation(
        SEND_CHATBOT_CONVO,
        {
            onCompleted(response) {
                console.log(response.sendChatBotConvo);
            },
            onError({ response, operation, graphQLErrors }) {
                console.log(response);
                console.log(graphQLErrors);
            }
        }
    );

    const [saveChatBotConvo] = useMutation(
        SAVE_CHATBOT_CONVO,
        {
            onCompleted(response) {
                console.log(response.saveChatBotConvo);
            },
            onError({ response, operation, graphQLErrors }) {
                console.log(response);
                console.log(graphQLErrors);
            }
        }
    );

    const handleMutationEvents = (newState) => {
        console.log('newState', newState)

        if (chatBotEmailRecipients && chatBotEmailRecipients.length > 0 && newState.consecutiveUnansweredQuestion && newState.consecutiveUnansweredQuestion >= 2) {
            console.log({
                variables: {
                    name: userFirstName + " " + userLastName,
                    studentEmail: userEmail,
                    recipientEmails: JSON.stringify(chatBotEmailRecipients.map(el => el.email)),
                    subject: 'Chabot Convo',
                    convo: JSON.stringify(newState.messages)
                }
            })
            sendChatBotConvo({
                variables: {
                    name: userFirstName + " " + userLastName,
                    studentEmail: userEmail,
                    recipientEmails: JSON.stringify(chatBotEmailRecipients.map(el => el.email)),
                    subject: 'Chabot Convo',
                    convo: JSON.stringify(newState.messages)
                }
            })
        }


        console.log({
            variables: {
                studentId: parseInt(userId),
                studentName: userFirstName + " " + userLastName,
                courseName: courseName,
                convoId: chatBotMessages[0].id.toString(),
                messages: JSON.stringify(newState.messages)
            }
        })

        saveChatBotConvo({
            variables: {
                studentId: parseInt(userId),
                studentName: userFirstName + " " + userLastName,
                courseName: courseName,
                convoId: chatBotMessages[0].id.toString(),
                messages: JSON.stringify(newState.messages)
            }
        })
    }

    const handleYesMoreQuestions = () => {
        const botMessage = createChatBotMessage(langFr ? "Quelle est votre question ?" : "What is your question ?");

        setState((prev) => {
            const newState = {
                ...prev,
                messages: [...prev.messages, botMessage],
            }

            handleMutationEvents(newState);

            return newState
        });
    };

    const handleNoMoreQuestions = () => {
        const botMessage = createChatBotMessage(langFr ? "Parfait. Avant de quitter, merci de me partager votre degré de satisfaction." : "Perfect. Before leaving, thank you for sharing your degree of satisfaction.",
            {
                widget: 'satisfaction',
            }
        );

        setState((prev) => {
            const newState = {
                ...prev,
                messages: [...prev.messages, botMessage],
            }

            handleMutationEvents(newState);

            return newState
        });
    };


    const handleSatisfactionAnswered = () => {
        const botMessage = [
            createChatBotMessage(langFr ? "Merci d'avoir répondu. Cliquer sur le (x) pour mettre fin à la conversation. À la prochaine." : "Thank you for answering. Click on the (x) to end the conversation. See you next time.")
        ];

        setState((prev) => {
            const newState = {
                ...prev,
                messages: [...prev.messages, ...botMessage],
            }

            handleMutationEvents(newState);

            return newState
        });
    };

    const handleAutomaticAnswerQ = ({ question, answer }) => {

        /* Remplacé par "answerToHTML"
        createChatBotMessage(question,
            {
                widget: 'answerCnt',
                payload: { answer: answer }
            }
        ),
        */

        const botMessage = [
            createChatBotMessage("Voici la meilleure réponse que j'ai trouvée à votre question:",
                {
                    widget: 'answerCnt',
                    payload: { answer: answer },
                }
            ),
            createChatBotMessage(langFr ? "Avez-vous d’autres questions pour aujourd’hui ?" : "Do you have any other questions for today ?",
                {
                    delay: 2000,
                    widget: 'moreQuestionsYesOrNo'
                }
            )
        ];

        setState((prev) => {

            prev.consecutiveUnansweredQuestion = 0;

            const newState = {
                ...prev,
                messages: [...prev.messages, ...botMessage],
            }

            handleMutationEvents(newState);

            return newState
        });
    };

    const handleAutomaticAnswerQSingleObj = {};

    chatBotState.chatBotQuestions.map((elQ, elQIndex) => {

        handleAutomaticAnswerQSingleObj['handleQ' + (elQIndex + 1)] = () => {

            const botMessage = [
                createChatBotMessage(elQ.question,
                    {
                        widget: 'answerCnt',
                        payload: { answer: elQ.answer }
                    }
                ),
                createChatBotMessage(langFr ? "Avez-vous d’autres questions pour aujourd’hui ?" : "Do you have any other questions for today ?",
                    {
                        delay: 2000,
                        widget: 'moreQuestionsYesOrNo'
                    }
                )
            ];

            setState((prev) => {

                prev.consecutiveUnansweredQuestion = 0;

                const newState = {
                    ...prev,
                    messages: [...prev.messages, ...botMessage],
                }

                handleMutationEvents(newState);

                return newState
            });
        }
    })

    const handleNoAnswer = () => {
        const botMessage = createChatBotMessage(
            chatBotState.consecutiveUnansweredQuestion === 0 ?
                (
                    langFr ?
                        "Désolé, je n'ai pas compris votre question. SVP veuillez la reformuler. \n\n***Veuillez noter que lors de la prochaine tentative, si je n'ai pas la réponse, votre question sera acheminée automatiquement à un formateur/formatrice, pour une réponse adéquate par courriel."
                        :
                        "Sorry, it seems I didn't understand your question. Please rephrase it. \n\n***Please note that on the next attempt, if I don't have the answer, your question will be automatically forwarded to a trainer, for an appropriate response by email."
                )
                :
                (
                    langFr ?
                        "Désolé, il semble que je n'ai pas compris votre question à nouveau. \n\nVotre question a été envoyée à un formateur/formatrice, vous recevez la réponse par courriel dans les brefs délais."
                        :
                        "Sorry, it seems I didn't understand your question again. \n\nYour question has been sent to a trainer, you will receive the answer by email as soon as possible."
                ),
            {
                delay: 1000
            });

        setState((prev) => {

            prev.consecutiveUnansweredQuestion = prev.consecutiveUnansweredQuestion + 1

            const newState = {
                ...prev,
                messages: [...prev.messages, botMessage],
            }

            handleMutationEvents(newState);

            return newState
        });


    };

    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        // handleDog,
                        handleYesMoreQuestions,
                        handleNoMoreQuestions,
                        handleSatisfactionAnswered,
                        handleAutomaticAnswerQ,
                        ...handleAutomaticAnswerQSingleObj,
                        handleNoAnswer
                    },
                });
            })}
        </div>
    );
};

export default ActionProvider;