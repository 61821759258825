import React from 'react';
import ActionProvider from '../ActionProvider';

const Answer = (props) => {
    return (
        <div className='react-chatbot-kit-chat-bot-message-container'>
            <div className={"react-chatbot-kit-chat-bot-message"}>
                <div className='react-chatbot-cnt'>
                    <div className={"chatbot-answer"} dangerouslySetInnerHTML={{ __html: props.payload.answer }}>
                        {/*wysiwig to html*/}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Answer;
